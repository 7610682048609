import React, { useEffect, useState } from "react";
import {
  Button,
  FormGroup,
  Link,
  Paper,
  Stack,
  SvgIcon,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import Header from "../header";
import Footer from "../footer";
import GoToTop from "../fab";
import DualHeading from "../../../components/DualHeading";
import { motion } from "framer-motion";
import {
  IC_CREDIT_CARD,
  IC_EMAIL,
  IC_PASSWORD,
  IC_UESR,
  IC_USERS,
} from "../../../assets/ui";
import { Player } from "@lottiefiles/react-lottie-player";
import PFMCheckbox from "../../../components/PFMCheckbox";
import PFMInput from "../../../components/PFMInput";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
  updateProfile,
} from "firebase/auth";
import { FbAuth } from "../../../authentication/firebase";
import { enqueueSnackbar } from "notistack";
import { RestPostSignup } from "../../../core/rest";
import { useNavigate } from "react-router";

export default function AffiliatesPage() {
  const desktop = useMediaQuery("(min-width: 700px)");

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [busy, setBusy] = useState(false);
  const [created, setCreated] = useState(false);
  const [passwordHasEnoughCharacters, setPasswordHasEnoughCharacters] =
    useState(false);
  const [passwordHasSymbol, setPasswordHasSymbol] = useState(false);

  const theme = useTheme();
  const navigate = useNavigate();

  async function signup(fe: any) {
    fe?.preventDefault?.();
    setError("");
    // Make sure all fields are valid...
    if (!name) {
      setError("name");
      return;
    }
    if (!email) {
      setError("email");
      return;
    }
    if (!password || !passwordHasEnoughCharacters || !passwordHasSymbol) {
      setError("password");
      return;
    }

    await continueSignup();
  }

  async function continueSignup() {
    setBusy(false);
    try {
      setBusy(true);
      // Create account on firebase.
      const acc = await createUserWithEmailAndPassword(FbAuth, email, password);
      if (acc.user) {
        // Update the user id
        await updateProfile(acc.user, {
          displayName: name,
        });
        await RestPostSignup(undefined, true);
        // Prompt for verification.
        await signOut(FbAuth);
        setCreated(true);
        enqueueSnackbar("Please check your email to confirm your account.");
      }
    } catch (err: any) {
      if (err.code) {
        if (err.code === "auth/weak-password") {
          enqueueSnackbar("Password must be atleast 6 characters long.", {
            variant: "error",
          });
        } else if (err.code === "auth/email-already-in-use") {
          // 2. Try signing in. If we succeed, we then re-send email verification. Otherwise, we login the user.
          try {
            const creds = await signInWithEmailAndPassword(
              FbAuth,
              email,
              password
            );
            if (creds && creds.user.emailVerified) {
              // Login
              navigate("/portal");
            } else if (creds && !creds.user.emailVerified) {
              // Send verification email
              await RestPostSignup(undefined, true);
              signOut(FbAuth);
              setCreated(true);
              enqueueSnackbar(
                "Please check your email to confirm your account."
              );
            }
          } catch (err: any) {
            // Invalid password?
            signOut(FbAuth);
            if (err.code && err.code === "auth/internal-error") {
              enqueueSnackbar("Too many attempts, please try again later. ", {
                variant: "error",
              });
            } else {
              enqueueSnackbar(
                "Error sending confirmation email. Please try again in a few minutes.",
                { variant: "error" }
              );
            }
          }
        } else if (err.code === "auth/internal-error") {
          enqueueSnackbar("Too many tries. Please try again later. ", {
            variant: "error",
          });
        } else {
          enqueueSnackbar(
            "Error signing up. Please try again or contact administrators.",
            { variant: "error" }
          );
        }
      } else {
        enqueueSnackbar(
          "Unknown error signing up. Please try again or contact administrators.",
          { variant: "error" }
        );
      }
    }
    setBusy(false);
    await signOut(FbAuth);
  }

  useEffect(() => {
    const symPat = /[-!$%^&*()_+|~=`{}\[\]:";'<>?,.\/@#]/;
    setPasswordHasSymbol(symPat.test(password));
    setPasswordHasEnoughCharacters(password.length >= 8);
  }, [password]);

  return (
    <Stack>
      <Header active="affiliate" />

      <Stack>
        <Stack
          alignItems={"center"}
          justifyContent={desktop ? "center" : "flex-start"}
          sx={{
            minHeight: desktop ? "100vh" : undefined,
            overflow: "hidden",
          }}
          spacing={desktop ? "24px" : "12px"}
        >
          <DualHeading
            sx={{
              fontSize: desktop ? 50 : 24,
              fontWeight: 500,
              minWidth: "50%",
              textAlign: "center",
            }}
            h1="Partner with"
            h2="Cashifybot"
            h2sx={{ color: "#48F" }}
          />
          <Typography
            fontSize={desktop ? 50 : 24}
            fontWeight={500}
            style={{ maxWidth: desktop ? "50%" : "90%" }}
            textAlign={"center"}
          >
            Become an Affiliate.
          </Typography>
          <Typography
            fontSize={desktop ? 18 : 12}
            fontWeight={400}
            style={{ maxWidth: desktop ? "35%" : "80%" }}
            textAlign={"center"}
          >
            Maximize your earnings by joining our partnership program. Become an
            affiliate, refer other users to Cashifybot, and enjoy upto 50%
            recurring commission on their Pro Plan upgrades!
          </Typography>

          <div
            style={{
              pointerEvents: "none",
              position: "absolute",
              overflow: "hidden",
              height: "100%",
              width: "100%",
              filter: desktop ? undefined : "blur(1.2px)",
              opacity: desktop ? 1 : 0.4,
              zIndex: desktop ? 0 : -1,
            }}
          >
            {/* Active subscriptions card  */}
            <motion.div
              transition={{ duration: 1 }}
              initial={{
                translateX: desktop ? "-500px" : "-200px",
              }}
              whileInView={{ translateX: desktop ? 100 : "24px" }}
            >
              <Paper
                sx={{
                  minWidth: desktop ? "300px" : undefined,
                  background: "#FFF1",
                  filter: "blur(5px)",
                  boxShadow: "0px 0px 10px 0px #0009",
                  border: "1px solid #FFF0",
                  position: "absolute",
                  transform: "translateY(48px)",
                  borderRadius: "8px",
                  transition: "all .3s",
                  ":hover": {
                    border: "1px solid #448A",
                  },
                }}
              >
                <Stack sx={{ p: "24px" }} spacing={"24px"}>
                  <Stack
                    direction={"row"}
                    spacing={"18px"}
                    alignItems={"center"}
                  >
                    <SvgIcon
                      sx={{
                        background: "#448",
                        height: desktop ? "48px" : "32px",
                        width: desktop ? "48px" : "32px",
                        p: desktop ? "12px" : "8px",
                        borderRadius: "8px",
                      }}
                    >
                      <IC_CREDIT_CARD />
                    </SvgIcon>
                    <Typography fontWeight={500} fontSize={desktop ? 20 : 14}>
                      Active Subscriptions
                    </Typography>
                  </Stack>
                  <Stack>
                    <Stack
                      alignItems={"center"}
                      direction={"row"}
                      spacing={"8px"}
                    >
                      <Typography fontWeight={500} fontSize={desktop ? 24 : 12}>
                        58
                      </Typography>
                      <Typography fontSize={desktop ? 24 : 12}>
                        Active
                      </Typography>
                    </Stack>
                    <Stack
                      alignItems={"center"}
                      direction={"row"}
                      spacing={"8px"}
                    >
                      <Typography fontSize={desktop ? 14 : 12}>9</Typography>
                      <Typography fontSize={10}>Will expire today</Typography>
                    </Stack>
                  </Stack>
                </Stack>
              </Paper>
            </motion.div>

            {/* Subscribers card  */}
            <motion.div
              transition={{ duration: 1 }}
              initial={{
                translateX: desktop ? "500px" : "-200px",
              }}
              whileInView={{ translateX: desktop ? "-100px" : "24px" }}
            >
              <Paper
                sx={{
                  minWidth: desktop ? "300px" : undefined,
                  background: "#FFF1",
                  filter: "blur(5px)",
                  boxShadow: "0px 0px 10px 0px #0009",
                  border: "1px solid #FFF0",
                  position: "absolute",
                  right: 0,
                  transform: "translateY(150px)",
                  borderRadius: "8px",
                  transition: "all .3s",
                  ":hover": {
                    border: "1px solid #448A",
                  },
                  zIndex: -10,
                }}
              >
                <Stack sx={{ p: "24px" }} spacing={"24px"}>
                  <Stack
                    direction={"row"}
                    spacing={"18px"}
                    alignItems={"center"}
                  >
                    <SvgIcon
                      sx={{
                        background: "#448",
                        height: desktop ? "48px" : "32px",
                        width: desktop ? "48px" : "32px",
                        p: desktop ? "12px" : "8px",
                        borderRadius: "8px",
                      }}
                    >
                      <IC_USERS />
                    </SvgIcon>
                    <Typography fontWeight={500} fontSize={desktop ? 20 : 14}>
                      Subscribers
                    </Typography>
                  </Stack>
                  <Stack>
                    <Stack
                      alignItems={"center"}
                      direction={"row"}
                      spacing={"8px"}
                    >
                      <Typography fontWeight={500} fontSize={desktop ? 24 : 12}>
                        70
                      </Typography>
                      <Typography fontSize={desktop ? 24 : 12}>
                        Active subscribers
                      </Typography>
                    </Stack>
                    <Stack
                      alignItems={"center"}
                      direction={"row"}
                      spacing={"8px"}
                    >
                      <Typography fontSize={desktop ? 14 : 12}>14</Typography>
                      <Typography fontSize={10}>New this month</Typography>
                    </Stack>
                  </Stack>
                </Stack>
              </Paper>
            </motion.div>
          </div>

          <Stack alignItems={"center"} spacing="12px">
            <Typography sx={{ fontSize: "16px", color: "#94969C" }}>
              Create your affiliate account now.
            </Typography>
          </Stack>
          <FormGroup>
            <Stack spacing="20px" sx={{ mt: "32px", width: "360px" }}>
              <PFMInput
                text={name}
                label="Name"
                errorText={error === "name" ? "Please enter your name." : ""}
                onUpdate={(t) => setName(t)}
                important
                placeholder="Enter your full name"
                startIcon={IC_UESR}
              />
              <PFMInput
                text={email}
                errorText={error === "email" ? "Please enter your email." : ""}
                onUpdate={(t) => setEmail(t)}
                label="Email"
                important
                placeholder="Enter your email"
                startIcon={IC_EMAIL}
              />

              <PFMInput
                text={password}
                errorText={
                  error === "password" ? "Please enter a valid password." : ""
                }
                onUpdate={(t) => setPassword(t)}
                label="Password"
                important
                password
                placeholder="Create a password"
                startIcon={IC_PASSWORD}
                onReturn={signup}
              />
              <Stack spacing={"2px"}>
                <PFMCheckbox
                  value={passwordHasEnoughCharacters}
                  label="Must be at least 8 characters"
                />
                <PFMCheckbox
                  value={passwordHasSymbol}
                  label="Must contain one special character"
                />
              </Stack>

              <Button
                disabled={busy}
                sx={{ mt: "4px", height: "42px" }}
                onClick={signup}
                variant="contained"
              >
                {busy && (
                  <Player
                    autoplay
                    loop
                    src="https://lottie.host/4c1c073a-93b5-4373-84d5-5af6feed4e53/DD5Yy0BUBe.json"
                    style={{ height: "100px" }}
                  />
                )}
                {!busy && "Get started"}
              </Button>
              <Stack
                justifyContent={"center"}
                direction={"row"}
                alignItems={"center"}
                sx={{ mt: "12px" }}
                spacing="8px"
              >
                <Typography color="#94969C" fontSize={"14px"}>
                  Already have an account?
                </Typography>
                <Link href="/login" underline="none" fontSize={"14px"}>
                  Login
                </Link>
              </Stack>
            </Stack>
          </FormGroup>
        </Stack>
      </Stack>
      <Footer />
      <GoToTop />
    </Stack>
  );
}
